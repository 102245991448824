<template>
  <b-sidebar
    v-model="sideBarStateComp"
    class="musique-opt"
    shadow
    backdrop
    @hidden="hiddenSideBar"
  >
    <div class="py-2 d-flex flex-column h-100" v-if="selectedPlanning">
      <section class="px-4 py-2">
        <h4 class="h4 mb-4">
          {{ $t('planification.planificationOptions') }}
        </h4>
        <MusiqueModalCurrentPlanning
          v-if="event && event.title"
          :event="event"
          @timeChanged="editTime"
          @formError="formErrorFn"
        />
        <h5 class="h5 mt-4">
          {{ $t('planification.weeklyReccurence') }}
        </h5>
        <div class="d-flex my-3">
          <b-badge
            class="badge-day cursor-pointer py-2 mr-1"
            v-for="(day, index) in days"
            :key="index"
            pill
            :variant="day.selected ? 'success' : 'light'"
            @click="dayClick(day, index)"
            >{{ day.label }}
          </b-badge>
        </div>

        <h5 class="h5 mb-0 mt-4" v-b-toggle.diffusion-period>
          <b-icon
            :icon="diffPeriod ? 'dash-circle-fill' : 'plus-circle-fill'"
            class="mr-2"
          ></b-icon>
          {{ $t('planification.diffusionPeriod') }}
        </h5>
        <b-collapse v-model="diffPeriod" id="diffusion-period">
          <MusiqueModalOptPeriod
            v-if="loaded"
            @changePeriodRange="changePeriodRange"
            :start-date="startDate"
            :end-date="endDate"
          />
        </b-collapse>

        <h5 class="h5 mb-0 mt-4" v-b-toggle.exclud-period>
          <b-icon
            :icon="excludPeriod ? 'dash-circle-fill' : 'plus-circle-fill'"
            class="mr-2"
          >
          </b-icon>
          {{ $t('planification.exclusionPeriods') }}
        </h5>
        <b-collapse v-model="excludPeriod" id="exclud-period">
          <MusiqueModalOptExclud
            v-if="dtException != null"
            :current-excludes="dtException"
            :scheduleId="selectedPlanning.extendedProps.scheduleId"
            @changeExlusion="changeExlusion"
          />
        </b-collapse>
        <h5 class="h5 mb-0 mt-4" v-b-toggle.weather-params>
          <b-icon
            :icon="weatherParams ? 'dash-circle-fill' : 'plus-circle-fill'"
            class="mr-2"
          ></b-icon>
          {{ $t('planification.weatherOptions') }}
        </h5>
        <b-collapse v-model="weatherParams" id="weather-params">
          <MusiqueModalOptWeather
            v-if="weather != null"
            @weatherParamsChange="changeWeatherParams"
            :current-weather="weather"
          />
        </b-collapse>
        <h-button
          :disabled="formError"
          @click="sendPlanning()"
          green
          class="mx-auto my-4 no-white-space d-flex align-items-center"
        >
          {{ $t('actions.save') }}
        </h-button>
      </section>

      <section class="mt-auto ">
        <MusiqueModalOptDelete
          v-if="selectedSite"
          :schedule-id="selectedPlanning.extendedProps.scheduleId"
          :event-displayed="eventDisplayed"
          :edit-mode="editMode"
          :selected-site="selectedSite"
        />
      </section>
    </div>
  </b-sidebar>
</template>

<script>
import { mapState } from 'vuex'
import MusiqueModalCurrentPlanning from '@/components/MusiqueModalCurrentPlanning'
import MusiqueModalOptPeriod from '@/components/MusiqueModalOptPeriod'
import MusiqueModalOptExclud from '@/components/MusiqueModalOptExclud'
import MusiqueModalOptDelete from '@/components/MusiqueModalOptDelete'
import dateFormat from '@/components/dateFormat'
import MusiqueModalOptWeather from '@/components/MusiqueModalOptWeather'

import musiqueService from '@/services/musiqueService'

import { EventBus } from '@/components/base/event-bus.js'

export default {
  components: {
    MusiqueModalCurrentPlanning,
    MusiqueModalOptPeriod,
    MusiqueModalOptExclud,
    MusiqueModalOptWeather,
    MusiqueModalOptDelete,
  },
  data() {
    return {
      savedAction: false,
      selectedPlanningData: null,
      loaded: false,
      flux: 0,
      startTime: null,
      endTime: null,
      startDate: null,
      endDate: null,
      dayOfWeekNumber: 0,
      calendarApi: null,
      eventDisplayed: null,
      diffPeriod: false,
      excludPeriod: false,
      dtException: null,
      weather: null,
      weatherParams: false,
      days: [
        { selected: false, label: this.$t('planification.monday') },
        { selected: false, label: this.$t('planification.tuesday') },
        { selected: false, label: this.$t('planification.wednesday') },
        { selected: false, label: this.$t('planification.thursday') },
        { selected: false, label: this.$t('planification.friday') },
        { selected: false, label: this.$t('planification.saturday') },
        { selected: false, label: this.$t('planification.sunday') },
      ],
      formError: false,
    }
  },
  methods: {
    async getScheduleDetails(id) {
      const res = await musiqueService.getSchedule(id)
      this.dtException = res.data.dtException
      this.weather = res.data.weather
    },
    dayClick(day, index) {
      day.selected = !day.selected
      if (day.selected) this.dayOfWeekNumber += Math.pow(2, index)
      else this.dayOfWeekNumber -= Math.pow(2, index)
    },
    editTime(val) {
      this.startTime = val.startTimes
      this.endTime = val.endTimes
    },
    formErrorFn(val) {
      this.formError = val
    },
    changePeriodRange(val) {
      this.startDate = val.start
      this.endDate = val.end
    },
    changeExlusion(val) {
      this.dtException = val
    },
    changeWeatherParams(val) {
      if (this.weather === -1 && val === 511) return
      else this.weather = val
    },
    sendPlanning() {
      let eventToSend = {
        active: this.editMode
          ? this.selectedPlanning.extendedProps.active
          : true,
        idBrand: this.$store.state.user.user.visibleBrands[0].id,
        idSkeleton: parseInt(this.event.idSkeleton),
        scheduleId: this.editMode
          ? parseInt(this.selectedPlanning.extendedProps.scheduleId)
          : -1,
        siteId: [this.selectedSite],
        // siteId: this.editMode
        //   ? this.selectedPlanning.extendedProps.siteId
        //   : this.$store.state.user.user.visibleSites.map((site) => site.id),
        idStream: this.flux,
        startTimes: this.startTime.length ? this.startTime : this.event.start,
        endTimes: this.endTime.length ? this.endTime : this.event.end,
        dayOfWeek: this.dayOfWeekNumber,
        weather: this.weather,
      }

      if (this.endDate) {
        eventToSend.startDate = dateFormat.encodeServerDate(this.startDate)
        eventToSend.endDate = dateFormat.encodeServerDate(this.endDate)
      } else {
        eventToSend.startDate = dateFormat.encodeServerDate('01/01/0001')
        eventToSend.endDate = dateFormat.encodeServerDate('01/01/0001')
      }
      if (this.dtException.length > 0) {
        eventToSend.dtException = this.dtException
      } else {
        eventToSend.dtException = []
      }
      musiqueService.postSchedule(eventToSend)
      this.savedAction = true
      this.$store.dispatch('musique/_openCloseSideBar', false)
    },
    hiddenSideBar() {
      if (this.savedAction)
        this.$store.dispatch('musique/_fetchingSchedulesState', true)
      this.selectedPlanningData = null
      this.loaded = false
      this.diffPeriod = false
      this.excludPeriod = false
      this.weatherParams = false
      this.dayOfWeekNumber = 0
      this.startTime = null
      this.endTime = null
      this.dtException = null
      this.startDate = null
      this.endDate = null
      this.days.forEach((day) => (day.selected = false))
      this.$store.dispatch('musique/_openCloseSideBar', false)
      this.$store.dispatch('musique/_openPlanning', null)
      if (this.savedAction) EventBus.$emit('fetchSchedules')
      else if (
        !this.savedAction &&
        this.eventDisplayed.extendedProps.idSkeleton == undefined
      )
        this.eventDisplayed.remove()
    },
  },
  computed: {
    selectedSite() {
      return this.$store.state.user.selectedSite.id
    },
    editMode() {
      return this.selectedPlanning.extendedProps.scheduleId > 0
    },
    event() {
      return {
        idSkeleton: this.editMode
          ? this.selectedPlanningData.extendedProps.idSkeleton
          : this.selectedPlanningData.id,
        imgSkeleton: this.selectedPlanningData.extendedProps.imgSkeleton,
        title: this.selectedPlanningData.title,
        startTimes: this.editMode
          ? this.selectedPlanningData.extendedProps.startTimes
          : [this.$moment(this.selectedPlanningData.start).format('HH:mm')],
        endTimes: this.editMode
          ? this.selectedPlanningData.extendedProps.endTimes
          : [this.$moment(this.selectedPlanningData.end).format('HH:mm')],
      }
    },
    dayOfWeekIndex() {
      const moment = this.$moment(this.selectedPlanning.start)
      const dow = moment.day()
      return dow
    },
    ...mapState({
      sideBarState: (state) => state.musique.sideBarOpt,
      selectedPlanning: (state) => state.musique.selectedPlanning,
    }),
    sideBarStateComp: {
      get() {
        return this.sideBarState
      },
      set(sideBar) {
        return sideBar
      },
    },
  },
  watch: {
    selectedPlanning() {
      if (this.selectedPlanning && this.selectedPlanning.start) {
        this.selectedPlanningData = JSON.parse(
          JSON.stringify(this.selectedPlanning)
        )
        const endMoment = this.$moment(this.selectedPlanning.end)
        const endDayIndex = endMoment.day()
        if (endDayIndex !== this.dayOfWeekIndex) {
          const newEndDate = this.$moment(
            this.selectedPlanningData.start
          ).format('YYYY-MM-DD[T]00:00:00Z')
          this.selectedPlanningData.end = newEndDate
        }

        //Mapping edit mode
        if (this.editMode) {
          this.getScheduleDetails(
            this.selectedPlanning.extendedProps.scheduleId
          )

          this.startDate = new Date(
            this.$moment(this.selectedPlanning.extendedProps.startDate).format(
              'YYYY, MM, DD'
            )
          )
          this.endDate = new Date(
            this.$moment(this.selectedPlanning.extendedProps.endDate).format(
              'YYYY, MM, DD'
            )
          )

          this.dayOfWeekNumber = this.selectedPlanning.extendedProps.dayOfWeek
          for (let i = 0; i < 7; i++) {
            if ((this.dayOfWeekNumber & (1 << i)) !== 0)
              this.days[i].selected = true
          }
          this.loaded = true
        } else {
          this.diffPeriod = false
          this.excludPeriod = false
          this.dtException = []
          this.weather = -1
          if (this.dayOfWeekIndex === 0) {
            this.dayClick(this.days[6], 6)
          } else {
            const posIndex = this.dayOfWeekIndex - 1
            this.dayClick(this.days[posIndex], posIndex)
          }
          this.loaded = true
        }
      }
    },
  },
  mounted() {
    EventBus.$on('calendarApi', (event) => {
      this.savedAction = false
      this.calendarApi = event.calendarApi
      this.eventDisplayed = event.event
      this.flux = event.flux
    })
  },
  beforeDestroy() {
    EventBus.$off('calendarApi')
  },
}
</script>

<style lang="scss" scoped>
.alert-delete {
  border: 0;
  background: #fae8e8;
  border-radius: 10px;
  color: #de1a1a;
}
.badge-light {
  background: #e7e9f3;
  color: $h-main-text-light;
}
.badge-day {
  padding: 0 0.75rem;
}
</style>
