var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-2 period"},[(_vm.excludes.length === 0)?_c('p',[_vm._v(" "+_vm._s(_vm.$t('planification.exclusionMessage'))+" ")]):_vm._e(),_c('transition-group',{attrs:{"name":"list"}},_vm._l((_vm.excludes),function(exclude,index){return _c('b-row',{key:index,staticClass:"mt-2 d-flex align-items-center"},[_c('b-col',{staticClass:"d-flex align-items-center pr-0",attrs:{"cols":"4"}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"calendar2-minus-fill"}}),_c('span',{staticClass:"mr-2 label"},[_vm._v(_vm._s(_vm.$t('planification.exclusion'))+" "+_vm._s(index + 1))])],1),_c('b-col',{staticClass:"pr-0",attrs:{"cols":"7"}},[_c('v-date-picker',{attrs:{"locale":_vm.$i18n.locale,"is-required":"","min-date":new Date(),"popover":{
            visibility: 'click',
          },"is-range":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var inputValue = ref.inputValue;
          var inputEvents = ref.inputEvents;
return [_c('div',{staticClass:"flex justify-center items-center"},[_c('input',_vm._g({staticClass:"w-100 range-input border px-2 py-1",domProps:{"value":inputValue.start
                    ? inputValue.start + ' - ' + inputValue.end
                    : _vm.$t('planification.choosePeriod')}},inputEvents.start))])]}}],null,true),model:{value:(_vm.excludes[index]),callback:function ($$v) {_vm.$set(_vm.excludes, index, $$v)},expression:"excludes[index]"}})],1),_c('b-col',{staticClass:"cursor-pointer",attrs:{"cols":"1"},on:{"click":function($event){return _vm.removeExclude(index)}}},[_c('b-icon',{staticClass:"text-red",attrs:{"scale":"0.*","icon":"trash-fill"}})],1)],1)}),1),_c('h-button',{staticClass:"my-2",attrs:{"outline":"","grey":"","small":""},on:{"click":_vm.newExclude}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"plus"}}),_vm._v(_vm._s(_vm.$t('planification.addExclusion'))+" ")],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }