<template>
  <div>
    <b-container fluid class="pb-0">
      <!-- <MusiqueFilters /> -->
    </b-container>

    <b-container fluid class="mt-4">
      <h-card>
        <b-row class="musique-content">
          <b-col xl="3" lg="4">
            <MusiqueDraggable />
          </b-col>
          <b-col xl="9" lg="8" class="mt-3 mt-lg-0 h-100">
            <SequenceEditor v-if="isEditingPlaylist" />
            <MusiqueCalendar v-else />
          </b-col>
        </b-row>
      </h-card>
    </b-container>
    <MusiqueModalOptions />
  </div>
</template>

<script>
import MusiqueDraggable from '@/components/MusiqueDraggable'
import MusiqueCalendar from '@/components/MusiqueCalendar'
import MusiqueModalOptions from '@/components/MusiqueModalOptions'
import SequenceEditor from '@/components/SequenceEditor'

export default {
  components: {
    MusiqueDraggable,
    MusiqueCalendar,
    MusiqueModalOptions,
    SequenceEditor,
  },
  computed: {
    isEditingPlaylist() {
      return this.$store.state.musique.editPlaylistMode
    },
  },
  created() {
    this.$store.commit('musique/SET_EDIT_PLAYLIST_MODE', false)
  },
}
</script>

<style lang="scss" scoped>
.musique-content {
  height: calc(100vh - 150px);
}
.overflow-auto {
  height: 100%;
  overflow-y: auto;
}
</style>
