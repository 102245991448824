<template>
  <div class="py-2 period">
    <p v-if="excludes.length === 0">
      {{ $t('planification.exclusionMessage') }}
    </p>
    <transition-group name="list">
      <b-row
        v-for="(exclude, index) in excludes"
        :key="index"
        class="mt-2 d-flex align-items-center"
      >
        <b-col cols="4" class="d-flex align-items-center pr-0">
          <b-icon icon="calendar2-minus-fill" class="mr-2"></b-icon>
          <span class="mr-2 label"
            >{{ $t('planification.exclusion') }} {{ index + 1 }}</span
          >
        </b-col>
        <b-col cols="7" class="pr-0">
          <v-date-picker
            :locale="$i18n.locale"
            is-required
            :min-date="new Date()"
            v-model="excludes[index]"
            :popover="{
              visibility: 'click',
            }"
            is-range
          >
            <template v-slot="{ inputValue, inputEvents }">
              <div class="flex justify-center items-center">
                <input
                  :value="
                    inputValue.start
                      ? inputValue.start + ' - ' + inputValue.end
                      : $t('planification.choosePeriod')
                  "
                  v-on="inputEvents.start"
                  class="w-100 range-input border px-2 py-1"
                />
              </div>
            </template>
          </v-date-picker>
        </b-col>
        <b-col cols="1" class="cursor-pointer" @click="removeExclude(index)">
          <b-icon scale="0.*" icon="trash-fill" class="text-red"></b-icon>
        </b-col>
      </b-row>
    </transition-group>

    <h-button @click="newExclude" outline grey small class="my-2">
      <b-icon icon="plus" class="mr-2"></b-icon
      >{{ $t('planification.addExclusion') }}
    </h-button>
  </div>
</template>

<script>
import dateFormat from '@/components/dateFormat'
export default {
  props: {
    currentExcludes: {
      type: Array,
    },
    scheduleId: {
      type: [String, Number],
    },
  },
  data() {
    return {
      excludes: [],
    }
  },
  created() {
    this.$nextTick(function() {
      if (this.currentExcludes.length) {
        this.excludes = []
        this.currentExcludes.forEach((elem) => {
          const obj = {
            start: new Date(elem.dtBegin),
            end: new Date(elem.dtEnd),
            id: elem.id,
            idRef: elem.idRef,
            idScheduled: elem.idScheduled,
          }
          this.excludes.push(obj)
        })
      }
    })
  },
  methods: {
    newExclude() {
      const obj = {
        start: null,
        end: null,
        id: -1,
        idRef: 0,
        idScheduled: this.scheduleId,
      }
      this.excludes.push(obj)
    },
    removeExclude(index) {
      this.excludes.splice(index, 1)
    },
  },
  watch: {
    excludes: {
      handler(val) {
        val.forEach((elem) => {
          elem['dtBegin'] = dateFormat.encodeServerDate(elem.start)
          elem['dtEnd'] = dateFormat.encodeServerDate(elem.end)
          elem['id'] = -1
          elem['idRef'] = 0
          elem['idScheduled'] = this.scheduleId
        })
        this.$emit('changeExlusion', val)
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.label {
  font-size: 15px;
}
.period,
.range-input {
  color: #646e84;
}
.text-red {
  color: #f65e7c;
}
.list-enter-active,
.list-leave-active {
  transition: all 0.3s;
}
.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>
