var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-100"},[_c('b-tabs',{staticClass:"d-flex flex-column h-100",attrs:{"content-class":"mt-3","pills":"","align":"center","nav-wrapper-class":"nav-tabs-category"}},_vm._l((_vm.categories),function(category,index){return _c('b-tab',{key:index,attrs:{"title":category.name,"title-item-class":"px-5"}},[(_vm.groupsLoaded && _vm.skeletonGroupsPublic.length && index === 0)?_c('skeleton-groups-filter',{staticClass:"mb-3",attrs:{"placeholder":_vm.$t('player.playerTagLabel'),"left-icon":"tags","options":_vm.skeletonGroupsPublic,"selectedTags":_vm.selectedGroups},model:{value:(_vm.filterGroups),callback:function ($$v) {_vm.filterGroups=$$v},expression:"filterGroups"}}):_vm._e(),_c('b-row',{staticClass:"px-2 skeletons-list"},[(_vm.groupsLoaded && index === 1)?_c('div',{staticClass:"px-2 mb-3 w-100"},[(_vm.hasCreateSkeletonRight)?_c('h-button',{staticClass:"w-100",attrs:{"blue":"","outline":"","disabled":_vm.editPlaylistMode &&
                _vm.currentSkeleton &&
                _vm.currentSkeleton.idSkeleton < 0},on:{"click":_vm.createPlaylist}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"plus"}}),_vm._v(" "+_vm._s(_vm.$t('planification.createPlaylist'))+" ")],1):_vm._e()],1):_vm._e(),(
            _vm.skeletonLoaded &&
              category.skeletons != null &&
              category.skeletons.length > 0
          )?[_vm._l((category.skeletons),function(skeleton,indexske){return _c('b-col',{key:indexske,staticClass:"px-2",attrs:{"lg":"6","md":"2","sm":"2","cols":"3"}},[_c('MusiqueDragItem',{attrs:{"img-id":skeleton.idImageCover,"data":_vm.dataEvent(skeleton),"canEditDelete":index === 1}})],1)}),(index === 1 && _vm.hasCreateSkeletonRight)?_c('b-col',{staticClass:"px-2",attrs:{"lg":"6","md":"2","sm":"2","cols":"3"}},[_c('MusiqueDragItemAddPlaylist')],1):_vm._e()]:(!category.skeletons)?_vm._l((12),function(skeleton,index){return _c('b-col',{key:index,staticClass:"px-2 mb-3",attrs:{"lg":"6","md":"6","sm":"2","cols":"3"}},[_c('MusiqueDragItemSkeleton')],1)}):(
            _vm.skeletonLoaded &&
              category.skeletons &&
              category.skeletons.length === 0
          )?[(index === 1 && _vm.hasCreateSkeletonRight)?_c('b-col',{staticClass:"px-2",attrs:{"lg":"6","md":"2","sm":"2","cols":"3"}},[_c('MusiqueDragItemAddPlaylist')],1):_c('div',{staticClass:"d-flex flex-column align-items-center justify-content-center"},[_c('img',{attrs:{"src":require("@/assets/svg/empty-pad.svg"),"alt":"empty pad","width":"80%"}}),_c('span',{staticClass:"text-center h5 mt-5"},[_vm._v(" "+_vm._s(_vm.$t('planification.noProg'))+" ")])])]:_vm._e()],2)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }